import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HistoricalResultValue, NameValue } from '@codenteam/portal/graphql';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ReportService {
  private runUUID = new BehaviorSubject<string | null>(null);
  runUUID$ = this.runUUID.asObservable();
  private pentestUUID = new BehaviorSubject<string | null>(null);
  pentestUUID$ = this.pentestUUID.asObservable();

  constructor(private sanitizer: DomSanitizer) {}
  setRun(runUUID: string) {
    this.runUUID.next(runUUID);
  }

  clearRun() {
    this.runUUID.next(null);
  }

  setPentest(pentestUUID: string) {
    this.pentestUUID.next(pentestUUID);
  }

  clearPentest() {
    this.pentestUUID.next(null);
  }

  getDate() {
    const today = new Date();
    const day = today.getDate();
    const lastMonth = today.getMonth();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    return {
      lastMonthDate: `${lastMonth}/${day}/${year};`,
      currentDate: `${month}/${day}/${year};`,
    };
  }
  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  objectHasValue(object: NameValue[]) {
    if (object) {
      return object.some((item) => item.value > 0);
    } else return false;
  }
  calculatePercentage(now: number, was: number, isRisk: boolean) {
    if (isNaN(now) || isNaN(was) || was === 0) return '';

    const difference = now - was;
    const percentage = Math.abs((difference / was) * 100);

    if (percentage === 0) return '';

    const arrowColor =
      (isRisk && difference < 0) || (!isRisk && difference > 0)
        ? 'border-lime-600'
        : 'border-red-800';
    const arrowDirection = difference > 0 ? 'rotate-[-135deg]' : 'rotate-45';

    return this.sanitizeHtml(`
      <div class="border-solid ${arrowColor} border-t-0 border-r-[3px] border-b-[3px] border-l-0 inline-block p-[3px] ${arrowDirection} mr-2"></div>
      ${percentage.toFixed(2)}%
    `);
  }
  ownedModulesNoteFn(
    condition: string,
    ownedModules: number,
    numberOfAllModules: number
  ) {
    const percentage = Math.floor(
      Math.min((ownedModules * 100) / numberOfAllModules, 100)
    );
    if (condition === 'ex-Employee' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-red-800">High-risk </span><span>there is (<span class="text-red-800">${percentage.toFixed(
          2
        )}%</span>) of modules owned by ex-Employees </span></div>`
      );
    } else if (condition === 'developer' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-orange-600">Medium-risk </span><span>there is (<span class="text-orange-600">${percentage.toFixed(
          2
        )}%</span>) of modules owned by single developer </span></div>`
      );
    } else if (condition === '' && percentage > 0) {
      return this.sanitizeHtml(
        `<div><span class="text-yellow-600">Low-risk </span><span>there is (<span class="text-yellow-600">${percentage.toFixed(
          2
        )}%</span>) of modules with no clear ownership </span> </div>`
      );
    } else {
      return '';
    }
  }
  highestContributingProfileNoteFn(
    highestContributing: any,
    highestContributingBeforeLast30Days: any,
    OwnCode: string
  ) {
    if (OwnCode) {
      return this.sanitizeHtml(
        `<div><span class="text-red-800">High-risk </span><span>${OwnCode}</span></div>`
      );
    } else if (
      highestContributingBeforeLast30Days &&
      highestContributing &&
      highestContributing.id !== highestContributingBeforeLast30Days.id &&
      highestContributingBeforeLast30Days.value !== 0
    ) {
      return this.sanitizeHtml(
        ` <div><span style="color: ${highestContributing.color}">${highestContributing.name}</span> Overtook <span style="color: ${highestContributingBeforeLast30Days.color}">${highestContributingBeforeLast30Days.name}</span></div>`
      );
    } else {
      return '';
    }
  }
  getHighestObjectWorkFactor(input: HistoricalResultValue[]) {
    if (input.length === 0) {
      return null;
    }
    const highestValue = input.reduce(
      (highest, item) => {
        const workFactor = item.workFactor ?? 0;

        if (workFactor > highest.workFactor) {
          return { ...item, workFactor };
        }

        return highest;
      },
      { workFactor: 0 }
    );
    return highestValue;
  }
}
